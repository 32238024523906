
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .head-tab{
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: var(--v-primary-base);
            bottom: 0;
            left: 0;
        }
    }
}
@media (min-width:1200px){
}
