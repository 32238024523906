
::v-deep{
    .v-input--checkbox .v-label {
        font-size: 1.4rem;
        color: #666;
    }
}
.v-application .rounded {
    border-radius: 12px !important;
}
.qna{
    &__mark{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        font-size: 1.6rem;
        font-weight: 700;
        color: #fff;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    ::v-deep{
        .v-input--checkbox .v-label {
            font-size: 1.6rem;
        }
    }
    .v-application .rounded {
        border-radius: 24px !important;
    }
    .qna{
        &__mark{
            width: 40px;
            height: 40px;
            font-size: 2rem;
        }
    }
}
@media (min-width:1200px){
}

