
::v-deep{
    .v-chip.v-size--large {
        border-radius: 14px;
        font-size: 1.4rem;
        height: 28px;
        min-width: 0;
        padding: 0 16px;
        &::before{
            display: none;
        }
    }
}
.recover-link{
    &--1{
        border-top-left-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
    }
    &--3{
        border-top-right-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
    }
}
.recover-head{
    &__left{
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100vw;
            height: 100%;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            background-color: #fafafa;
            z-index: 0;
        }
        &__logo{
            position: absolute;
            width: 240px;
            bottom: 0;
            left: 0;
            opacity: .1;
        }
        &__contents{
            position: relative;
            z-index: 1;
        }
    }
}
.scroll-area{
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar{width: 4px;}
    &::-webkit-scrollbar-thumb{width: 4px; background-color: #aaa; border-radius: 2px}
    &::-webkit-scrollbar-track{width: 4px; background-color: #ececec; border-radius: 2px;}
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    ::v-deep{
        .v-chip.v-size--large {
            border-radius: 14px;
            font-size: 1.4rem;
            height: 28px;
            min-width: 0;
            padding: 0 16px;
        }
    }
    .recover-head{
        &__left{
            position: relative;
            &::before{
                left: unset;
                right: 0;
                transform: translateX(0);
            }
            &__logo{
                width: 380px;
            }
        }
    }
    .recover-link{
        &--1{
            border-top-left-radius: 24px !important;
            border-bottom-left-radius: 24px !important;
        }
        &--3{
            border-top-right-radius: 24px !important;
            border-bottom-right-radius: 24px !important;
        }
    }
}
@media (min-width:1200px){
}

