
.line{
    display: block;
    width: 1px;
    height: 16px;
    background-color: var(--border-color);
}
.v-btn.grey-9--text.v-btn--active{
    &:not(:hover)::before{
        opacity: 0;
    }
    color: rgba(0, 0, 0, .54) !important;
}
